/** @jsx jsx */
import { jsx } from "theme-ui"
import { RiHeart2Line } from "react-icons/ri"
import { Link } from "gatsby"

const Footer = () => (
  <footer
    className="site-footer"
    sx={{
      bg: "siteColor",
    }}
  >
    <div className="container">
      <p>
        The Top Offensive with{" "}
        <span className="icon -love">
          <RiHeart2Line />
        </span>{" "}
        Copyright © 2024 thetopoffensive.com |{" "}
        <Link to="/privacy">Privacy</Link>
      </p>
    </div>
  </footer>
)

export default Footer
